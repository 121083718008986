<template>
  <div class="relative">
    <div class="w-full absolute hidden md:block z-40">
      <Header/>
    </div>
    <div class="flex flex-col relative space-top">
      <div class="blog-container h-60 sm:h-80 md:h-96 lg:h-100 w-full z-20">
        <div class="overlay-color">
          <div class="max-w-screen-md mx-auto px-4 h-full flex items-center">
            <div class="flex-1 text-center text-white-1">
              <h1 class="font-semibold font-tahu text-3xl sm:text-4xl lg:text-6xl mb-3 text-shadow md:text-shadow-md">
                Our Signature Wax?
              </h1>
              <p class="font-semibold text-sm sm:text-xl lg:text-3xl lg:px-5 xl:px-0">
                Know what is our secret!
              </p>
              <span class="md:text-2xl font-semibold italic">Nadine Bautista</span>
            </div>
          </div>
        </div>
      </div>

      <div class="z-30">
        <div class="mx-auto h-154 xs:h-150 mobileL:h-150 md:h-174 w-10/12 lg:w-7/12 relative">
          <div class="z-30 absolute -top-16 rounded-md w-full bg-white-2 py-4 md:py-10">
            <div class="flex flex-col items-center md:text-center px-4 h-full">
              <img class="w-24 lg:w-32" src="../assets/images/home-page/blog/leaves.png" />

              <div class="blog text-black-1">
                <h3 class="text-center font-semibold text-sm sm:text-md md:text-2xl lg:text-4xl mb-5">
                  Our Signature Wax
                </h3>

                <h5 class="font-semibold text-xs md:text-base mb-5">
                  We all know that Lay Bare Waxing Salon has been a staple
                  amongst many Filipinas for 15 years and counting. What makes
                  our Loyalistas devoted to our services goes beyond beauty;
                  it’s a lifestyle.
                </h5>

                <img class="mb-5 w-100 mx-auto" src="../assets/images/home-page/blog/blog-internal.jpg" />

                <h5 class="font-semibold text-xs md:text-base mb-5 md:mb-10">
                  Apart from our variety of skincare products that have come and
                  left our shelves, this particular one is the root of it all.
                  It’s what established our brand to what it has become now -
                  and that is simply our patented cold wax jelly called Thorough
                  Care.
                </h5>

                <div class="flex justify-center items-center space-x-5">
                  <div class="flex-1">
                    <img class="mb-5 w-80 mx-auto" src="../assets/images/home-page/blog/waxing-ingredients.jpg" />
                  </div>
                  <div class="flex-1 text-left">
                    <p class="font-semibold text-sm md:text-lg lg:text-2xl">
                      ALL THE GOOD STUFF
                    </p>
                    <p class="text-sm md:text-base italic">
                      Sugar, Honey & Calamansi
                    </p>
                  </div>
                </div>

                <h5 class="font-semibold text-xs md:text-base mb-5">
                  Our signature recipe goes way back. We’ve developed tons of
                  mixture but this particular one is just the perfect balance to
                  suit every skin type.
                </h5>

                <h5 class="font-semibold text-xs md:text-base mb-5">
                  Sugar helps with the overall consistency of the wax. It also
                  promotes the removal of dead skin cells without damaging your
                  skin. Honey on the other hand has antimicrobial properties
                  which lessens the risk of inflammation while your pores are
                  still open. Lastly, Calamansi helps lighten the skin as it has
                  Vitamin C which nourishes and leaves you with supple skin
                  after your waxing session.
                </h5>

                <h5 class="font-semibold text-xs md:text-base mb-5">
                  Try Lay Bare’s Thorough Care now! You can book your
                  appointment through the Lay Bare app.
                </h5>
              </div>
            </div>
          </div>
        </div>

        <div class="max-w-screen-xl mx-auto px-5">
          <div class="w-full text-center mb-5">
            <h4 class="font-semibold text-black-1 text-xl lg:text-3xl">
              Want more?
            </h4>
          </div>

          <div class="grid grid-cols-1 md:grid-cols-3 gap-5 mb-5">
            <div v-for="(a, i) in sortByDate.slice(0, 3)" :key="i">
              <a :href="a.link" target="_blank">
                <div class="article h-52 md:h-80 text-white-1 font-semibold"
                  v-bind:style="{
                    background:
                      'url(' +
                      require(`../assets/images/home-page/blog/${a.thumbNail}`) +
                      ') no-repeat center center',
                  }">
                  <div class="w-full h-full overlay flex flex-col items-center justify-center px-5">
                    <h4>{{ a.author }}</h4>

                    <h3 class="text-xl lg:text-3xl text-center">
                      {{ a.articleName }}
                    </h3>
                    <h3 v-if="a.datePublished !== 'N/A'">
                      {{ a.datePublished }}
                    </h3>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer class="z-40" />
  </div>
</template>

<script>
import Header from '../components/navigation/LaybareHeader.vue';
import Footer from '../components/footer/LaybareFooter.vue';
export default {
  components: {
    Header,
    Footer
  },

  data() {
    return {
      articles: [
        {
          id: 0,
          articleName: 'Lay Bare Brazilian Wax Review',
          author: 'KarenMNL',
          datePublished: 'January 17, 2017',
          link: 'https://www.karenmnl.com/lay-bare-brazilian-wax-review/',
          thumbNail: 'karenmnl.jpg',
        },
        {
          id: 1,
          articleName: 'Manila Primer: Lay Bare Waxing Salon',
          author: 'Melanie Suzon',
          datePublished: 'August 25, 2015',
          link:
            'https://primer.com.ph/beauty-fashion/2015/08/26/lay-bare-waxing-salon/',
          thumbNail: 'Manila-Primer.jpg',
        },
        {
          id: 2,
          articleName:
            'Lay Bare Now Sells Products Online (Including PPE) Amidst the COVID-19 Pandemic',
          author: 'Angeline Rodriguez',
          datePublished: 'June 24, 2020',
          link:
            'https://www.wheninmanila.com/lay-bare-now-sells-products-online-including-ppe/',
          thumbNail: 'wheninmanila.jpg',
        },
        {
          id: 3,
          articleName:
            'Calyxta: OUR CALYXTA CONTRIBUTOR TRIED SILK BY LAY BARE AND HERE’S HER REVIEW',
          author: 'Patty Sunio aka Tita Pacita',
          datePublished: 'June 26, 2019',
          link:
            'https://www.calyxta.com/articles/product-reviews/our-calyxta-contributor-tried-silk-by-lay-bare-and-heres-her-review/',
          thumbNail: 'calyxta.jpg',
        },
        {
          id: 4,
          articleName: 'The Beauty Junkee: BARE READY WITH LAY BARE',
          author: '',
          datePublished: 'February 23, 2016',
          link:
            'https://thebeautyjunkee.blogspot.com/2016/02/bare-ready-with-lay-bare.html',
          thumbNail: 'thebeautyjunkee.jpg',
        },
        {
          id: 5,
          articleName: 'GoodWork announces partnership with Lay Bare',
          author: '',
          datePublished: '2020',
          link: 'https://faq.goodwork.ph/goodwork-laybare/',
          thumbNail: 'thegoodwork.jpg',
        },
        {
          id: 6,
          articleName: 'Ms. Fiona Hilario of Lay Bare Waxing Salon',
          author: '',
          datePublished: 'N/A',
          link:
            'https://www.betheboss.com/blog/fiona-hilario-of-lay-bare-waxing-salon/',
          thumbNail: 'karenmnl.jpg',
        },
        {
          id: 7,
          articleName: 'Keep Calm and Lay Bare',
          author: 'Chonx Tibajia',
          datePublished: 'November 28, 2012',
          link:
            'https://www.philstar.com/lifestyle/fashion-and-beauty/2012/11/28/874941/keep-calm-and-lay-bare',
          thumbNail: 'Manila-Primer.jpg',
        },
        {
          id: 8,
          articleName:
            'Business World: Lay Bare aims to triple store network in 5 years',
          author: 'Arra B. Francia',
          datePublished: 'May 15, 2018',
          link:
            'https://www.bworldonline.com/lay-bare-aims-to-triple-store-network-in-5-years/',
          thumbNail: 'wheninmanila.jpg',
        },
      ],
    }
  },
  computed: {
    sortByDate() {
      let allArticles = this.articles

      allArticles.sort((a, b) => {
        return new Date(b.datePublished) - new Date(a.datePublished)
      })

      return allArticles
    },
  },

  mounted() {
    this.$gtag.pageview(this.$route);
  },
}
</script>

<style scoped>
.space-top {
  margin-top: 90px;
}

.blog-container {
  background: url('../assets/images/home-page/blog/blog-banner-v2.jpg')
    no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@media (max-width: 480px) {
  .space-top {
    margin-top: 60px;
  }
}

@media (min-width: 1280px) {
  .space-top {
    margin-top: 100px;
  }
}

@media (min-width: 1536px) {
  .space-top {
    margin-top: 128px;
  }
}
</style>
